import apiService from 'services/apiService';
import Classroom from 'models/Classroom';
import StudentGradebook from 'models/StudentGradebook';
import User from 'models/User';
import StudentProfile from 'models/StudentProfile';
import Assignment from 'models/Assignment';
import Book from 'models/Book';
import MissedWord from 'models/MissedWord';
import AssignmentSubmission from 'models/AssignmentSubmission';
import StudentQuestion from 'models/StudentQuestion';
import QuestionResponse from 'models/QuestionResponse';
import StudentGradebookEntry from 'models/StudentGradebookEntry';
import { parseError } from 'utils/utils';
import CachingEngine from 'utils/CachingEngine';

export class StudentService {
  joinClassroom = async (
    user: string,
    id: string,
    access_code: string,
  ): Promise<Classroom> => {
    try {
      const response = await apiService.api.post('/students/', {
        user: user,
        classroom: id,
        access_code: access_code,
      });
      return Classroom.fromServerClassroom(response.data.classroom);
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  getGradebook = async (
    student_profile_id: string,
    classroom_id: string,
  ): Promise<StudentGradebook> => {
    try {
      // const cachedGradebook = await CachingEngine.getData(
      //   `${classroom_id}-gradebook`,
      // );
      // if (cachedGradebook)
      //   return StudentGradebook.fromServerStudentGradebook(cachedGradebook);

      const response = await apiService.api.get(
        `/students/${student_profile_id}/gradebook/`,
      );

      const gradebook = StudentGradebook.fromServerStudentGradebook(
        response.data,
      );
      // await CachingEngine.setData(`${classroom_id}-gradebook`, gradebook);
      return gradebook;
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  getStudentProfile = async (
    user: User,
    classroom: Classroom,
  ): Promise<StudentProfile> => {
    try {
      const cachedProfile = await CachingEngine.getData(
        `${classroom.getId()}-profile`,
      );
      if (cachedProfile)
        return StudentProfile.fromServerStudentProfile(cachedProfile);

      const response = await apiService.api.get(
        `/users/${user.getId()}/get_student_profile/?classroom=${classroom.getId()}`,
      );
      const studentProfile = StudentProfile.fromServerStudentProfile(
        response.data,
      );
      await CachingEngine.setData(
        `${classroom.getId()}-profile`,
        studentProfile,
      );
      return studentProfile;
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  getBook = async (assignment: Assignment): Promise<Book> => {
    try {
      const response = await apiService.api.get(`/books/${assignment.book}/`);
      var book = Book.fromServerBook(response.data);
      // await book.getHtmlContent();
      return book;
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  registerMissedWord = async (
    word: string,
    assignment_submission: string,
    index: number,
    known: boolean,
    isKeyWord: boolean,
  ): Promise<MissedWord> => {
    try {
      const response = await apiService.api.post('/missed_words/', {
        word: word,
        assignment_submission: assignment_submission,
        word_index: index,
        known: known,
        isKeyWord: isKeyWord,
      });
      return MissedWord.fromServerMissedWord(response.data);
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  getAssignmentMissedWords = async (
    assignmentSubmission: AssignmentSubmission,
  ): Promise<MissedWord[]> => {
    try {
      const response = await apiService.api.get(
        `/submissions/${assignmentSubmission.getId()}/missed_word_list`,
      );
      return response.data.map((respWord: Record<string, any>) =>
        MissedWord.fromServerMissedWord(respWord),
      );
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  getStudentMissedWords = async (studentId: string): Promise<MissedWord[]> => {
    try {
      const response = await apiService.api.get(
        `/students/${studentId}/missed_words`,
      );
      return response.data.map((respWord: Record<string, any>) =>
        MissedWord.fromServerMissedWord(respWord),
      );
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  updateAssignmentSubmission = async (
    assignment_submission: AssignmentSubmission,
  ): Promise<AssignmentSubmission> => {
    try {
      const response = await apiService.api.patch(
        `/submissions/${assignment_submission.getId()}/`,
        {
          ...assignment_submission.toJSON(),
        },
      );
      return AssignmentSubmission.fromServerAssignmentSubmission(response.data);
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  getAssignmentQuestions = async (
    assignment_submission: AssignmentSubmission,
  ): Promise<StudentQuestion[]> => {
    try {
      const response = await apiService.api.get(
        `/submissions/${assignment_submission.id}/questions`,
      );
      return response.data.questions.map((respQuestion: Record<string, any>) =>
        StudentQuestion.fromServerStudentQuestion({
          question: respQuestion,
          response: response.data.responses.find(
            (respObj: QuestionResponse) => respObj.question === respQuestion.id,
          ),
        }),
      );
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  updateQuestionResponse = async (
    question_response: QuestionResponse,
  ): Promise<Record<string, any>> => {
    try {
      const response = await apiService.api.put(
        `/questionresponses/${question_response.getId()}/`,
        {
          ...question_response.toJSON(),
        },
      );
      return {
        question_response: QuestionResponse.fromServerQuestionResponse(
          response.data.question_response,
        ),
        assignment_submission:
          AssignmentSubmission.fromServerAssignmentSubmission(
            response.data.submission,
          ),
      };
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  getAssignmentById = async (
    assignmentId: string,
    assignment?: StudentGradebookEntry | null,
  ): Promise<StudentAssignmentApiResponse | null> => {
    try {
      if (!assignment) {
        const response = await apiService.api.get(
          `/assignments/${assignmentId}/`,
        );

        assignment = StudentGradebookEntry.fromServerEntry(response.data);
      }

      if (!assignment) return null;
      if (
        ['Flashcards', 'Pronounce'].includes(
          assignment.assignment.assignment_type,
        )
      )
        return { assignment: assignment };

      const book = await this.getBook(assignment.assignment);

      if (assignment.assignment.assignment_type === 'Multi-Part') {
        await book.getJsonContent();
      } else {
        await book.getHtmlContent();
      }

      return {
        assignment: assignment,
        book: book,
        questions: await this.getAssignmentQuestions(
          assignment.assignment_submission,
        ),
      };
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  getPronunciation = async (word: string): Promise<Blob> => {
    try {
      const response = await apiService.api.post(
        '/get_pronunciation/',
        { word: word },
        { responseType: 'blob' },
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };

  generateReading = async (
    studentId: string,
    missedWords: string[],
  ): Promise<any> => {
    try {
      const response = await apiService.api.post(
        `/students/${studentId}/generate_reading/`,
        { missed_words: missedWords },
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return Promise.reject(new Error(parseError(error)));
    }
  };
}

const studentService = new StudentService();
export default studentService;

type StudentAssignmentApiResponse = {
  assignment: StudentGradebookEntry;
  book?: Book;
  questions?: StudentQuestion[];
};
