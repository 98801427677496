import React, { ChangeEvent, useEffect, useState } from 'react';
import Button from 'view/components/buttons/Button';
import StudentQuestion from 'models/StudentQuestion';
import { AudioAssistanceButton } from '../buttons/AudioAssistanceButton';
import MultipleChoiceOption from 'models/MultipleChoiceOption';
import ItemPanel from '../common/ItemPanel';
import { Grid } from '@mui/material';
import ImageChoice from './ImageChoice';

import 'view/style/student/components/assignment/questionViewer.css';

export enum QuestionViewerState {
  InProgress = 0,
  Locked = 1,
  Grading = 2,
  Graded = 3,
}

interface StudentQuestionsViewerProps {
  state: QuestionViewerState;
  questions: StudentQuestion[];
  grade?: number;
  onQuestionChange: (
    question: StudentQuestion,
    response: string,
    choice?: string,
    completed?: boolean,
  ) => StudentQuestion | undefined;
  onExit: () => void;
}

interface TeacherQuestionsViewerProps {
  state: QuestionViewerState;
  questions: StudentQuestion[];
  grade?: number;
  onUpdateGrade: (question: StudentQuestion) => void;
  onExit: () => void;
}

type QuestionsViewerProps =
  | StudentQuestionsViewerProps
  | TeacherQuestionsViewerProps;

export const QuestionsViewer: React.FC<QuestionsViewerProps> = (props) => {
  const [selectedQuestion, setSelectedQuestion] = useState<StudentQuestion>(
    props.questions.find((q) => q.question.index === 1) as StudentQuestion,
  );
  const [responseText, setResponseText] = useState<string>(
    selectedQuestion?.response.response || '',
  );
  const [responseChoice, setResponseChoice] = useState<string>();
  const [questions, setQuestions] = useState<StudentQuestion[]>(
    props.questions,
  );

  const isStudentProps = (
    props: QuestionsViewerProps,
  ): props is StudentQuestionsViewerProps => {
    return (
      (props as StudentQuestionsViewerProps).onQuestionChange !== undefined
    );
  };

  // Type guard for TeacherQuestionsViewerProps
  const isTeacherProps = (
    props: QuestionsViewerProps,
  ): props is TeacherQuestionsViewerProps => {
    return (props as TeacherQuestionsViewerProps).onUpdateGrade !== undefined;
  };

  // Sync `questions` state with `props.questions` whenever `props.questions` changes.
  useEffect(() => {
    setQuestions(props.questions);
  }, [props.questions]);

  useEffect(() => {
    const foundQuestion = questions.find(
      (q) => q.question.getId() === selectedQuestion.question.getId(),
    ) as StudentQuestion;
    setSelectedQuestion(foundQuestion);
    setResponseChoice(foundQuestion.response.choice);
    setResponseText(foundQuestion.response.response);
  }, [questions, selectedQuestion.question]);

  const handleQuestionChanged = (question: StudentQuestion) => {
    // Save the response for the current question before changing to the new one.
    if (selectedQuestion && isStudentProps(props)) {
      props.onQuestionChange(selectedQuestion, responseText, responseChoice);
    }
    // Update the selected question and its response.
    setSelectedQuestion(question);
    setResponseText(question.response.response);
    setResponseChoice(question.response.choice);
  };

  const handleSubmit = () => {
    if (isTeacherProps(props) && props.state === QuestionViewerState.Grading) {
      questions.forEach((q) => {
        let foundQuestion = props.questions.find(
          (pq) => pq.question.getId() === q.question.getId(),
        ) as StudentQuestion;
        if (q.response.grade !== foundQuestion.response.grade) {
          props.onUpdateGrade(q);
        }
      });
    } else if (
      selectedQuestion &&
      (responseText.trim() !== '' || responseChoice !== undefined) &&
      props.state === QuestionViewerState.InProgress &&
      isStudentProps(props)
    ) {
      const updatedQuestion = props.onQuestionChange(
        selectedQuestion,
        responseText,
        responseChoice,
      );
      if (updatedQuestion) setSelectedQuestion(updatedQuestion);
    }
  };

  const handleUpdateQuestionGrade = (event: ChangeEvent<HTMLInputElement>) => {
    const numericValue = parseInt(event.target.value, 10) || 0;
    setQuestions((prevQuestions) => [
      ...prevQuestions.filter((q) => q !== selectedQuestion),
      StudentQuestion.fromServerStudentQuestion({
        ...selectedQuestion,
        response: {
          ...selectedQuestion.response,
          grade: numericValue,
        },
      }),
    ]);
  };

  const handleMultipleChoiceOptionToggle = (choice: MultipleChoiceOption) => {
    if (props.state !== QuestionViewerState.InProgress) return;
    if (responseChoice === choice.id) {
      setResponseChoice(undefined);
    } else {
      setResponseChoice(choice.id);
    }
  };

  return (
    <div className="questions-viewer">
      <div className="questions-viewer-sidebar-container">
        <div className="questions-viewer-sidebar">
          <div className="question-list">
            {props.questions.map((_, idx) => {
              const question = props.questions.find(
                (q) => q.question.index === idx + 1,
              ) as StudentQuestion;
              return (
                <div
                  key={question?.question.index}
                  className={`question-select${
                    idx + 1 === selectedQuestion?.question.index
                      ? ' selected'
                      : ''
                  }`}
                  onClick={() => handleQuestionChanged(question)}
                >
                  <label className="label-normal">Question {idx + 1}</label>
                  <input
                    type="checkbox"
                    readOnly
                    checked={question?.response.completed}
                  />
                </div>
              );
            })}
          </div>
          {(props.state === QuestionViewerState.Grading ||
            props.state === QuestionViewerState.Graded) && (
            <div className="grade-input">
              <label className="label-normal">Grade</label>
              {props.state === QuestionViewerState.Grading ? (
                <>
                  <input
                    type="number"
                    value={selectedQuestion.response.grade}
                    onChange={handleUpdateQuestionGrade}
                  />
                  /100
                </>
              ) : (
                <span className="label-normal">
                  {selectedQuestion.response.grade}/100
                </span>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="questions-viewer-content">
        <label className="label-large">Questions</label>
        <div className="question-input">
          <div className="row">
            <label className="label-medium">
              {selectedQuestion?.question.text}
            </label>
            {props.state !== QuestionViewerState.Grading && (
              <AudioAssistanceButton text={selectedQuestion?.question.text} />
            )}
          </div>
          {selectedQuestion.question.choices?.length ? (
            <MultipleChoiceSelector
              choices={selectedQuestion.question.choices}
              selectedChoice={responseChoice}
              onChoiceToggle={handleMultipleChoiceOptionToggle}
            />
          ) : (
            <textarea
              readOnly={props.state !== QuestionViewerState.InProgress}
              value={responseText}
              onChange={(event) => setResponseText(event.target.value)}
            />
          )}
        </div>
        {(props.state === QuestionViewerState.InProgress ||
          props.state === QuestionViewerState.Grading) && (
          <Button type="go" onClick={handleSubmit} text="Submit" label="Save" />
        )}
        {props.state === QuestionViewerState.Graded && props.grade && (
          <span className="label-large">
            Total Question Grade: {props.grade}%
          </span>
        )}
      </div>
    </div>
  );
};

export default QuestionsViewer;

type MulitpleChoiceSelectorProps = {
  choices: MultipleChoiceOption[];
  selectedChoice?: string;
  onChoiceToggle: (choice: MultipleChoiceOption) => void;
};

const MultipleChoiceSelector: React.FC<MulitpleChoiceSelectorProps> = ({
  choices,
  selectedChoice,
  onChoiceToggle,
}) => {
  const [isVisual, setIsVisual] = useState<boolean>(false);

  useEffect(() => {
    setIsVisual(choices.some((c) => !!c.img));
  }, [choices, setIsVisual]);

  return (
    <>
      {isVisual ? (
        <Grid container>
          {choices.map((choice) => (
            <Grid item key={`grid-item-${choice.id}`}>
              <ImageChoice
                key={`choice-${choice.id}`}
                id={`choice-${choice.id}`}
                selected={selectedChoice === choice.id}
                onClick={() => onChoiceToggle(choice)}
                onDoubleClick={() => onChoiceToggle(choice)}
              >
                <img src={choice.img} alt="" />
              </ImageChoice>
            </Grid>
          ))}
        </Grid>
      ) : (
        <div className="item-list">
          {choices.map((choice) => (
            <ItemPanel
              key={`choice-${choice.id}`}
              id={`choice-${choice.id}`}
              selected={selectedChoice === choice.id}
              onClick={() => onChoiceToggle(choice)}
              onDoubleClick={() => onChoiceToggle(choice)}
            >
              <span className="label-medium">{choice.text}</span>
            </ItemPanel>
          ))}
        </div>
      )}
    </>
  );
};
