import React from 'react';
import { MultiPartAssignmentContent } from 'view/components/student/newcomers/MultipartAssignment';
import AssignmentQuestion from '../questions/AssignmentQuestion';

interface MultiPartQuestionFrameProps {
  content: MultiPartAssignmentContent;
  onUpdateContent: (updatedContent: MultiPartAssignmentContent) => void;
  onDeleteFrame: (index: number) => void;
}

const MultiPartQuestionFrame: React.FC<MultiPartQuestionFrameProps> = ({
  content,
  onUpdateContent,
  onDeleteFrame,
}) => {
  const handleDeleteQuestion = (question: any) => {
    onDeleteFrame(question.index);
  };

  const handleUpdateQuestion = (question: any, text: string) => {
    const updatedContent = {
      ...content,
      text: text,
    };

    onUpdateContent(updatedContent);
  };

  const handleAddQuestionChoice = () => {
    const updatedContent = {
      ...content,
      choices: [
        ...(content.choices ?? []),
        {
          text: '',
          id: content.choices?.length ?? 0,
          index: content.choices?.length ?? 0,
          correct: false,
        },
      ],
    };

    onUpdateContent(updatedContent);
  };

  const handleDeleteQuestionChoice = (question: any, choice: any) => {
    const updateChoices = [
      ...(content.choices ?? []).filter((c) => c.index !== choice.index),
    ];
    const updatedContent = {
      ...content,
      choices: updateChoices,
      type: !updateChoices.length ? 'shortAnswer' : content.type,
    };

    onUpdateContent(updatedContent);
  };

  const handleUpdateQuestionChoice = (
    question: any,
    choice: any,
    text: string,
    correct: boolean,
  ) => {
    const updatedChoices = [...content.choices!];
    updatedChoices[choice.index] = { ...choice, correct: correct, text: text };
    const updatedContent: MultiPartAssignmentContent = {
      ...content,
      choices: updatedChoices,
      type: choice.img ? 'visual' : 'multipleChoice',
    };

    onUpdateContent(updatedContent);
  };

  return (
    <>
      <AssignmentQuestion
        question={content as any}
        onDeleteQuestion={handleDeleteQuestion}
        onQuestionTextChange={handleUpdateQuestion}
        onUpdateQuestion={() => {}}
        onAddQuestionChoice={handleAddQuestionChoice}
        onDeleteQuestionChoice={handleDeleteQuestionChoice}
        onModifyQuestionChoice={handleUpdateQuestionChoice}
      />
    </>
  );
};

export default MultiPartQuestionFrame;
