import useApiCall from 'contexts/ApiCall';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import aiService from 'services/aiService';
import Button from 'view/components/buttons/Button';
import { AppPage } from 'view/components/common/AppPage';
import LanguageSelector from 'view/components/common/LanguageSelector';
import AppSidebar from 'view/components/common/Sidebar';
import { UserInput } from 'view/components/common/UserInput';
import MultipartAssignment, {
  MultiPartAssignmentContent,
} from 'view/components/student/newcomers/MultipartAssignment';

export const NewcomersPage: React.FC = () => {
  const [frames, setFrames] =
    useState<Record<number, MultiPartAssignmentContent>>();
  const [topic, setTopic] = useState<string>();
  const [readingDifficulty, setReadingDifficulty] = useState<string>();
  const [length, setLength] = useState<number>(8);
  const navigate = useNavigate();
  const makeApiCall = useApiCall();

  const handleGenerateAssignment = () => {
    makeApiCall(
      aiService.generateNewcomerJson,
      topic,
      readingDifficulty,
      length,
    )
      .then((resp) => setFrames(resp))
      .catch((error) => alert(error));
  };

  return (
    <AppPage>
      <div className="app-page-content">
        <AppSidebar onExit={() => navigate('/student/home')}>
          <>
            <UserInput
              type="text"
              id="topic"
              label="Topic"
              value={topic}
              onChange={(event) => setTopic(event.target.value)}
            />
            <UserInput
              type="text"
              id="topic"
              label="Reading difficulty"
              value={readingDifficulty}
              onChange={(event) => setReadingDifficulty(event.target.value)}
            />
            <UserInput
              type="number"
              id="topic"
              label="Length"
              value={length}
              onChange={(event) => setLength(event.target.value)}
            />
            <LanguageSelector />
            <Button
              onClick={handleGenerateAssignment}
              type="go"
              label="Generate"
              text="generate"
            />
          </>
        </AppSidebar>
        <div className="app-main-content">
          <h1>Language Practice</h1>
          {frames && <MultipartAssignment frames={frames} />}
        </div>
      </div>
    </AppPage>
  );
};

// const testFrames: Record<number, MultiPartAssignmentContent> = {
//   0: {
//     type: 'multipleChoice',
//     text: 'Maria saw a cat stuck in a tree outside her window. She called the local animal rescue to help get the cat down safely. Why did Maria call the animal rescue?',
//     choices: [
//       { text: 'Because she wanted to keep the cat as a pet.', correct: false },
//       {
//         text: 'Because the cat was stuck in the tree and needed help.',
//         correct: true,
//       },
//       { text: 'Because she was afraid of cats.', correct: false },
//     ],
//     index: 0,
//     id: 0,
//   },
//   1: {
//     type: 'shortAnswer',
//     text: 'Describe a memorable experience.',
//     index: 1,
//     id: 1,
//   },
//   2: {
//     type: 'readAloud',
//     text: 'Once upon a time, in a quiet village, a little girl named Mia discovered a sparkling stone by the river. As she held it, the stone began to glow, and suddenly, the world around her changed. The river turned into a path of light, leading to a hidden forest filled with glowing creatures. With excitement in her heart, Mia stepped forward, curious to see where this magical journey would take her.',
//     index: 2,
//     id: 2,
//   },
//   3: {
//     type: 'conversation',
//     text: "Hi, I fell down and hurt my back. Do you know how to get to the nurse's office?",
//     index: 3,
//     id: 3,
//   },
//   4: {
//     type: 'multipleChoice',
//     text: 'Hi _____ is your name?',
//     choices: [
//       { text: 'what', correct: true },
//       { text: 'who', correct: false },
//       { text: 'when', correct: false },
//     ],
//     index: 4,
//     id: 4,
//   },
// };
