import React, { useEffect, useState } from 'react';
import { useUserContext } from 'contexts/UserContext';
import Modal from 'view/components/common/Modal';
import { SearchBar } from 'view/components/common/SearchBar';
import { Filter, FilterOption } from 'view/components/common/Filter';
import Book from 'models/Book';
import { catalogService } from 'services/catalogService';
import { BookRow } from 'view/components/catalog//BookRow';
import { BookPreview } from 'view/components/catalog/BookPreview';
import { BookReader } from 'view/components/common/BookReader';
import contentService from 'services/contentService';
import useApiCall from 'contexts/ApiCall';
import AppSidebar from 'view/components/common/Sidebar';

import 'view/style/catalog/catalog.css';

const filter_list: string = require('assets/icons/filter_list.svg').default;

interface BookCatalogProps {
  onExit: () => void;
  onSelect?: (book: Book) => void;
}

export const BookCatalog: React.FC<BookCatalogProps> = ({
  onExit,
  onSelect,
}) => {
  const { user } = useUserContext();
  const [books, setBooks] = useState<Book[]>();
  const [likedBooks, setLikedBooks] = useState<Book[]>();
  const [searchBooks, setSearchBooks] = useState<Book[]>();
  const [selectedBook, setSelectedBook] = useState<Book>();
  const [readingDifficultyRange, setReadingDifficutlyRange] = useState<
    Array<number>
  >([0, 1400]);
  const constants = contentService.getTeacherConstants();
  const [genreOptions, setGenreOptions] = useState<Array<FilterOption>>(
    constants.genres.map((genre: string) => ({
      is_active: true,
      value: genre,
    })),
  );
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [viewBook, setViewBook] = useState<boolean>(false);
  const makeApiCall = useApiCall();

  useEffect(() => {
    if (!user || books) return;
    makeApiCall(catalogService.getCatalogBooks)
      .then((respBooks) => {
        setBooks(respBooks.books);
        setSearchBooks(respBooks.books);
        setLikedBooks(respBooks.liked_books);
      })
      .catch((error) => alert(error.message));
  }, [user, books, makeApiCall]);

  useEffect(() => {
    // Do all filtering of displayed books here
    if (
      searchTerm === undefined ||
      !readingDifficultyRange ||
      !genreOptions ||
      !books
    )
      return;
    const lowerSearchTerm = searchTerm.toLowerCase();
    const activeGenres = genreOptions
      .filter((option) => option.is_active)
      .map((option) => option.value);
    setSearchBooks(
      books?.filter((book) => {
        const numericValue = parseInt(
          book.reading_difficulty?.replace('L', ''),
          10,
        );
        const isWithinReadingRange =
          numericValue >= readingDifficultyRange[0] &&
          numericValue <= readingDifficultyRange[1];
        const isOfActiveGenre = activeGenres.indexOf(book.genre) !== -1;
        const titleContainsSearchTerm = book.title
          .toLowerCase()
          .includes(lowerSearchTerm);
        const tagsContainsSearchTerm = book.tags.some((tag) =>
          tag.toLowerCase().includes(lowerSearchTerm),
        );
        return (
          isWithinReadingRange &&
          isOfActiveGenre &&
          (titleContainsSearchTerm || tagsContainsSearchTerm)
        );
      }),
    );
  }, [searchTerm, readingDifficultyRange, genreOptions, books]);

  const handleLikeBook = (book: Book, liked: boolean) => {
    if (!user) return;
    makeApiCall(catalogService.addLikedBook, user, book, liked)
      .then((resp) => {
        if (liked) {
          setLikedBooks((prevLikedBooks) => [
            ...(prevLikedBooks as Book[]),
            book,
          ]);
        } else {
          setLikedBooks((prevLikedBooks) => [
            ...(prevLikedBooks as Book[]).filter((b) => b.id !== book.id),
          ]);
        }
      })
      .catch((error) => alert(error.message));
  };

  const handleReadingDifficutlyRangeChange = (
    event: Event,
    value: number | number[],
    activeThumb: number,
  ) => {
    const values = value as number[];
    setReadingDifficutlyRange(values);
  };

  const handleGenreFilterChange = (checked: boolean, genre: FilterOption) => {
    setGenreOptions((prevGenres) => {
      const genreIdx = prevGenres.indexOf(genre);
      const updatedGenres = [...prevGenres];
      updatedGenres[genreIdx] = { ...prevGenres[genreIdx], is_active: checked };
      return updatedGenres;
    });
  };

  const handleSelectBook = async (book: Book) => {
    if (book && (!book.html_content || book.html_content === '')) {
      await book.getHtmlContent();
    }
    setSelectedBook(book);
  };

  return (
    <div className="app-page-content">
      <AppSidebar onExit={onExit}>
        <>
          <div className="sidebar-content-data">
            <SearchBar onSearch={setSearchTerm} />
          </div>
          <div className="sidebar-content-data">
            <div className="sidebar-content-label">
              <img src={filter_list} alt="" />
              <label className="label-small">Filter</label>
            </div>
            <Filter
              type="range"
              label="Reading Difficulty"
              options={[]}
              max={1400}
              rangeInput={readingDifficultyRange}
              onRangeChange={handleReadingDifficutlyRangeChange}
            />
            <Filter
              type="multichoice"
              label="Genre"
              options={genreOptions}
              onOptionChange={handleGenreFilterChange}
            />
          </div>
        </>
      </AppSidebar>
      <div className="app-main-content">
        <h1>Book Catalog</h1>
        <div className="book-catalog-content">
          <BookRow
            label="Public Books"
            books={searchBooks || []}
            onSelect={handleSelectBook}
          />
          {likedBooks && likedBooks.length > 0 && (
            <BookRow
              label="My Liked Books"
              books={likedBooks || []}
              onSelect={handleSelectBook}
            />
          )}
        </div>
      </div>
      {selectedBook && (
        <Modal
          isOpen={selectedBook ? true : false}
          onClose={() => setSelectedBook(undefined)}
        >
          <BookPreview
            book={selectedBook}
            liked={likedBooks?.indexOf(selectedBook) !== -1}
            onLike={handleLikeBook}
            onView={() => setViewBook(true)}
            onSelect={onSelect}
          />
        </Modal>
      )}
      {selectedBook && viewBook && (
        <Modal isOpen={viewBook} onClose={() => setViewBook(false)}>
          <div className="reading-container">
            <BookReader
              book={selectedBook}
              startIndex={0}
              isReading={false}
              showMissedWordPopup={false}
              setIsReading={() => {}}
              onMissedWord={() => {}}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};
