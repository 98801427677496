import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useClassroomContext } from 'contexts/ClassroomContext';
import Assignment from 'models/Assignment';
import Book from 'models/Book';
import teacherService from 'services/teacherService';
import Question from 'models/Question';
import { AppPage } from 'view/components/common/AppPage';
import { extractTextFromFiles } from 'utils/fileUtils';
import useApiCall from 'contexts/ApiCall';
import BaseAssignmentForm from 'view/components/teacher/assignment/forms/Assignment';
import { QuestionEditor } from 'view/components/teacher/assignment/QuestionEditor';
import Modal from 'view/components/common/Modal';
import ListeningAssignmentForm from 'view/components/teacher/assignment/forms/Listening';
import { BookCatalog } from 'view/components/catalog/BookCatalog';
import SpeakingAssignmentForm from 'view/components/teacher/assignment/forms/Speaking';
import { useGradebook } from 'contexts/TeacherGradebookContext';
import MultiPartAssignmentForm from 'view/components/teacher/assignment/newcomers/Multipart';
import PracticeAssignmentForm from 'view/components/teacher/assignment/forms/Practice';
import Classroom from 'models/Classroom';

import 'view/style/teacher/assignment.css';

export const TeacherAssignmentPage: React.FC = () => {
  const { classroom } = useClassroomContext();
  const { gradebook, setGradebook } = useGradebook();
  const [assignment, setAssignment] = useState<Assignment>();
  const [book, setBook] = useState<Book>();
  const [editQuestions, setEditQuestions] = useState<boolean>(false);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [viewBooks, setViewBooks] = useState<boolean>(false);
  const [isAutoGenerateAllowed, setIsAutoGenerateAllowed] =
    useState<boolean>(true);
  const [coverImageFile, setCoverImageFile] = useState<File>();
  const [coverImage, setCoverImage] = useState<string>();
  const { assignmentId, assignmentType } = useParams();
  const makeApiCall = useApiCall();
  const navigate = useNavigate();

  useEffect(() => {
    if (!classroom) return;
    if (!assignment) {
      setAssignment(Assignment.generateDefault(classroom, assignmentType));
    }
    if (!book) {
      setBook(Book.generateDefault(classroom));
    }
  }, [assignment, book, classroom, assignmentType]);

  useEffect(() => {
    if (assignmentId) {
      makeApiCall(teacherService.getAssignmentById, assignmentId)
        .then(async (resp) => {
          setAssignment(resp.assignment);
          setBook(resp.book);
          setQuestions(resp.questions);
        })
        .catch((error) => alert(error.message));
    }
  }, [assignmentId, makeApiCall]);

  const handleCancel = () => {
    if (!classroom) return;
    window.history.back();
  };

  const updateAssignment = (event: any) => {
    if (!assignment || !classroom) return;
    var value = event.target.value;
    if (event.target.id === 'min_reading_level') {
      value = Math.min(
        Math.max(classroom.min_reading_level, value),
        assignment.max_reading_level,
      );
    } else if (event.target.id === 'max_reading_level') {
      value = Math.max(
        Math.min(classroom.max_reading_level, value),
        assignment.min_reading_level,
      );
    }
    setAssignment((prevAssignment: any) => {
      return Assignment.fromServerAssignment({
        ...prevAssignment,
        [event.target.id]: value,
      });
    });
  };

  const updateBook = (event: any) => {
    if (!book || !classroom) return;
    var value = event.target.value;
    if (event.target.id === 'reading_difficulty') {
      value = Math.min(Math.max(event.target.value, 0), 2000);
    }
    setBook((prevBook: any) => {
      return Book.fromServerBook({
        ...prevBook,
        [event.target.id]: value,
      });
    });
  };

  const handleCreateAssignment = (
    generateAudio?: boolean,
    shouldAnalyze: boolean = true,
  ) => {
    if (!book || !assignment || !classroom) return;
    if (!assignmentId) {
      if (book.id === '' || true) {
        makeApiCall(
          teacherService.createBook,
          book,
          coverImageFile,
          generateAudio,
          shouldAnalyze,
        )
          .then((resp) =>
            makeApiCall(
              teacherService.createAssignment,
              resp.id,
              assignment,
              questions,
            )
              .then((respAssignment) => {
                if (gradebook) {
                  setGradebook(gradebook.addAssignment(respAssignment));
                }
                navigate(`/teacher/classroom/${classroom.getId()}/`);
              })
              .catch((error) => {
                setBook((prev) =>
                  Book.fromServerBook({
                    ...resp,
                    html_content: book.html_content,
                  }),
                );
                alert(error.message);
              }),
          )
          .catch((error) => alert(error));
      } else {
        //   makeApiCall(
        //     teacherService.createAssignment,
        //     book.id,
        //     assignment,
        //     questions,
        //   )
        //     .then((respAssignment) => {
        //       if (gradebook) {
        //         setGradebook(gradebook.addAssignment(respAssignment));
        //       }
        //       navigate(`/teacher/classroom/${classroom.getId()}/`);
        //     })
        //     .catch((error) => alert(error.message));
      }
    } else {
      makeApiCall(
        teacherService.updateAssignment,
        book,
        assignment,
        questions,
        coverImageFile,
        generateAudio,
      )
        .then((respAssignment) => {
          setGradebook(gradebook!.updateAssignment(respAssignment));
          navigate(`/teacher/classroom/${classroom.getId()}/`);
        })
        .catch((error) => alert(error.message));
    }
  };

  const handleUpdateText = (html_content: string) => {
    if (!book) return;
    setBook((prevBook: any) => {
      return Book.fromServerBook({
        ...prevBook,
        html_content: html_content,
        // text: extractInnerTextFromHTML(html_content),
      });
    });
  };

  const handleUpdateJson = (json_content?: Record<any, any>) => {
    if (!book) return;
    setBook((prevBook: any) => {
      return Book.fromServerBook({
        ...prevBook,
        json_content: json_content,
        // text: extractInnerTextFromHTML(html_content),
      });
    });
  };

  const handleCreateQuestion = (text: string, choices?: any[]) => {
    if (!assignment) return;
    if (assignmentId) {
      makeApiCall(
        teacherService.createQuestion,
        text,
        questions.length + 1,
        assignment,
        choices,
      )
        .then((respQ) =>
          setQuestions((prevQuestions) => [...prevQuestions, respQ]),
        )
        .catch((error) => alert(error.message));
    } else {
      setQuestions((prevQuestions) => [
        ...prevQuestions,
        new Question(
          (prevQuestions.length + 1).toString(),
          '',
          text,
          prevQuestions.length + 1,
          100,
          choices,
        ),
      ]);
    }
  };

  const handleUpdateQuestion = (question: Question) => {
    setQuestions((prevQuestions) => [
      ...prevQuestions.filter((q) => q.getId() !== question.getId()),
      question,
    ]);
    if (assignmentId) {
      makeApiCall(teacherService.updateQuestion, question).catch((error) =>
        alert(error.message),
      );
    }
  };

  const handleDeleteQuestion = (question: Question) => {
    if (assignmentId) {
      makeApiCall(teacherService.deleteQuestion, question)
        .then((resp) =>
          setQuestions((prevQuestions) => [
            ...prevQuestions
              .filter((q) => q.getId() !== question.getId())
              .map((q) =>
                Question.fromQuestion(
                  new Question(
                    q.getId(),
                    q.assignment,
                    q.text,
                    q.index < question.index ? q.index : q.index - 1,
                    q.max_points,
                    q.choices ? [...q.choices] : undefined,
                  ),
                ),
              ),
          ]),
        )
        .catch((error) => alert(error.message));
    } else {
      setQuestions((prevQuestions) => [
        ...prevQuestions
          .filter((q) => q.getId() !== question.getId())
          .map((q) =>
            Question.fromQuestion(
              new Question(
                q.getId(),
                q.assignment,
                q.text,
                q.index < question.index ? q.index : q.index - 1,
                q.max_points,
                q.choices ? [...q.choices] : undefined,
              ),
            ),
          ),
      ]);
    }
  };

  const handleDeleteAssignment = () => {
    if (!assignment || !assignmentId) return;
    makeApiCall(teacherService.deleteAssignment, assignmentId)
      .then((resp) => {
        if (gradebook) {
          setGradebook(gradebook.removeAssignment(assignment));
        }
        navigate(`/teacher/classroom/${classroom?.getId()}`);
      })
      .catch((error) => {
        if (gradebook) {
          setGradebook(gradebook.removeAssignment(assignment));
        }
        navigate(`/teacher/classroom/${classroom?.getId()}`);
      })
      .finally(() => setAssignment(undefined));
  };

  const handleSelectCatalogBook = async (book: Book) => {
    var catBook = Book.fromServerBook({ ...book });
    await catBook.getHtmlContent();
    setBook(catBook);
    setAssignment((prev) =>
      Assignment.fromServerAssignment({
        ...(prev as Assignment),
        title: book.title,
      }),
    );
    setViewBooks(false);
  };

  const handleBookCoverChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files?.[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const imageAsString = e.target?.result;
        setCoverImage(imageAsString?.toString());
      };

      reader.readAsDataURL(file);
      setCoverImageFile(file);
    }
  };

  const handleInputFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (!event.target.files) return;
    makeApiCall(extractTextFromFiles, event.target.files)
      .then((respText) =>
        setBook((prev) =>
          Book.fromServerBook({
            ...prev,
            html_content: respText,
          }),
        ),
      )
      .catch((error) => alert(error.message));
  };

  if (!assignment || !book) return <AppPage />;

  return (
    <AppPage>
      {assignment.assignment_type === 'Default' && (
        <BaseAssignmentForm
          assignment={assignment}
          book={book}
          setAssignment={setAssignment}
          updateAssignment={updateAssignment}
          updateBook={updateBook}
          handleInputFileChange={handleInputFileChange}
          handleBookCoverChange={handleBookCoverChange}
          handleCreateAssignment={handleCreateAssignment}
          handleDeleteAssignment={handleDeleteAssignment}
          setViewBooks={() => setViewBooks(true)}
          setViewQuestions={() => setEditQuestions(true)}
          handleSelectCatalogBook={handleSelectCatalogBook}
          handleUpdateText={handleUpdateText}
          onCancel={handleCancel}
        />
      )}
      {(assignment.assignment_type === 'Listening' ||
        assignment.assignment_type === 'Read-Aloud') && (
        <ListeningAssignmentForm
          assignment={assignment}
          book={book}
          coverImage={coverImage}
          updateAssignment={updateAssignment}
          updateBook={updateBook}
          handleInputFileChange={handleInputFileChange}
          handleBookCoverChange={handleBookCoverChange}
          handleCreateAssignment={handleCreateAssignment}
          handleDeleteAssignment={handleDeleteAssignment}
          setAssignment={setAssignment}
          setViewBooks={() => setViewBooks(true)}
          setViewQuestions={() => setEditQuestions(true)}
          handleUpdateText={handleUpdateText}
          onCancel={handleCancel}
        />
      )}
      {assignment.assignment_type === 'Speaking' && (
        <SpeakingAssignmentForm
          assignment={assignment}
          book={book}
          coverImage={coverImage}
          setAssignment={setAssignment}
          updateAssignment={updateAssignment}
          updateBook={updateBook}
          handleInputFileChange={handleInputFileChange}
          handleBookCoverChange={handleBookCoverChange}
          handleCreateAssignment={handleCreateAssignment}
          handleDeleteAssignment={handleDeleteAssignment}
          setViewBooks={() => setViewBooks(true)}
          setViewQuestions={() => setEditQuestions(true)}
          handleUpdateText={handleUpdateText}
          onCancel={handleCancel}
        />
      )}
      {assignment.assignment_type === 'Multi-Part' && (
        <MultiPartAssignmentForm
          assignment={assignment}
          book={book}
          setAssignment={setAssignment}
          updateAssignment={updateAssignment}
          updateBook={updateBook}
          updateContent={handleUpdateJson}
          handleInputFileChange={handleInputFileChange}
          handleBookCoverChange={handleBookCoverChange}
          handleCreateAssignment={handleCreateAssignment}
          handleDeleteAssignment={handleDeleteAssignment}
          handleSelectCatalogBook={handleSelectCatalogBook}
          onCancel={handleCancel}
        />
      )}
      <Modal
        isOpen={
          assignment.assignment_type === 'Flashcards' ||
          assignment.assignment_type === 'Pronounce'
        }
        onClose={handleCancel}
      >
        <PracticeAssignmentForm
          assignment={assignment}
          classroom={classroom as Classroom}
          onExit={handleCancel}
        />
      </Modal>
      <Modal isOpen={editQuestions} onClose={() => setEditQuestions(false)}>
        <QuestionEditor
          book={book}
          questions={questions}
          defaultQuestions={book?.default_questions}
          isAutoGenerateAllowed={
            book.html_content !== undefined &&
            book.html_content.length > 50 &&
            isAutoGenerateAllowed
          }
          setIsAutoGenerateAllowed={setIsAutoGenerateAllowed}
          onCreateQuestion={handleCreateQuestion}
          onUpdateQuestion={handleUpdateQuestion}
          onDeleteQuestion={handleDeleteQuestion}
          onExit={() => setEditQuestions(false)}
        />
      </Modal>
      <Modal
        isOpen={viewBooks}
        onClose={() => setViewBooks(false)}
        style={{
          width: '95vw',
          height: '95vh',
          maxWidth: '95vw',
          maxHeight: '95vh',
        }}
      >
        <BookCatalog
          onExit={() => setViewBooks(false)}
          onSelect={handleSelectCatalogBook}
        />
      </Modal>
    </AppPage>
  );
};
