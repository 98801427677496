import { Grid } from '@mui/material';
import useApiCall from 'contexts/ApiCall';
import Book from 'models/Book';
import React, { useEffect, useState } from 'react';
import aiService from 'services/aiService';
import googleApiService from 'services/googleAPIService';
import CachingEngine from 'utils/CachingEngine';
import { AudioAssistanceButton } from 'view/components/buttons/AudioAssistanceButton';
import Button from 'view/components/buttons/Button';
import IconButton from 'view/components/buttons/IconButton';
import AudioRecorder from 'view/components/common/AudioRecorder';
import { BookReader } from 'view/components/common/BookReader';
import ItemPanel from 'view/components/common/ItemPanel';
import ReadingFrame from 'view/components/common/ReadingFrame';
import SpeechBubble from 'view/components/common/SpeechBubble';

import 'view/style/student/components/newcomer/multipartAssignment.css';
import ImageChoice from '../ImageChoice';

const check = require('assets/icons/check-solid.svg').default; // check-solid
const xMark = require('assets/icons/xmark-solid.svg').default;
const pencil = require('assets/icons/pencil.svg').default;

export type MultiPartAssignmentContentType =
  | 'multipleChoice'
  | 'shortAnswer'
  | 'readAloud'
  | 'conversation'
  | 'visual';

export type MultiPartAssignmentContent = {
  type: MultiPartAssignmentContentType;
  text: string;
  choices?: Record<string, any>[];
  index: number;
  id: number | string;
  check?: boolean;
};

type MultiPartAssignmentResponse = {
  text: string;
  choice: number | null;
  correct: boolean;
  feedback: string;
  attempts: number;
};

const initialResponse: MultiPartAssignmentResponse = {
  text: '',
  choice: null,
  correct: false,
  feedback: '',
  attempts: 0,
};

type MultiPartAssignmentProps = {
  frames: Record<number, MultiPartAssignmentContent>;
  onCompletion?: () => void;
};

const MultipartAssignment: React.FC<MultiPartAssignmentProps> = ({
  frames,
  onCompletion,
}) => {
  const [mutableFrames, setMutableFrames] = useState<
    Record<number, MultiPartAssignmentContent> | undefined
  >(frames);
  const [activeFrameIdx, setActiveFrameIdx] = useState<number>(0);
  const [responses, setResponses] = useState<MultiPartAssignmentResponse[]>(
    Object.keys(frames).map((key) => {
      return { ...initialResponse };
    }),
  );

  const handlePrevious = () => {
    setActiveFrameIdx((prev) => Math.max(prev - 1, 0));
  };

  const handleNext = () => {
    setActiveFrameIdx((prev) =>
      Math.min(prev + 1, Object.keys(frames!).length - 1),
    );
  };

  const handleCheck = () => {
    if (
      !mutableFrames ||
      mutableFrames[activeFrameIdx].check ||
      mutableFrames[activeFrameIdx].type === 'readAloud' ||
      (responses[activeFrameIdx].choice === null &&
        responses[activeFrameIdx].text === '')
    )
      return;
    setMutableFrames((prev) => {
      return {
        ...prev,
        [activeFrameIdx]: { ...prev![activeFrameIdx], check: true },
      };
    });
    var updatedResponses = [...responses];
    updatedResponses[activeFrameIdx] = {
      ...updatedResponses[activeFrameIdx],
      attempts: updatedResponses[activeFrameIdx].attempts + 1,
    };
    setResponses(updatedResponses);

    if (
      updatedResponses.every((response) => response.attempts >= 1) &&
      onCompletion
    ) {
      console.log('completed');
      onCompletion();
    }
  };

  const updateResponse = (
    index: number,
    response: MultiPartAssignmentResponse,
    resetCheck: boolean = true,
  ) => {
    var updatedResponses = [...responses];
    updatedResponses[index] = response;
    setResponses(updatedResponses);
    // reset the check flag
    if (!resetCheck) return;
    setMutableFrames((prev) => {
      return {
        ...prev,
        [activeFrameIdx]: { ...prev![activeFrameIdx], check: false },
      };
    });
  };

  if (!mutableFrames) return null;

  return (
    <div className="reading-container">
      <ReadingFrame>
        {mutableFrames[activeFrameIdx].type === 'multipleChoice' && (
          <QuestionFrame
            content={mutableFrames[activeFrameIdx]}
            response={responses[activeFrameIdx]}
            updateResponse={updateResponse}
          />
        )}
        {mutableFrames[activeFrameIdx].type === 'shortAnswer' && (
          <QuestionFrame
            content={mutableFrames[activeFrameIdx]}
            response={responses[activeFrameIdx]}
            updateResponse={updateResponse}
          />
        )}
        {mutableFrames[activeFrameIdx].type === 'readAloud' && (
          <ReadAloudFrame
            content={mutableFrames[activeFrameIdx]}
            response={responses[activeFrameIdx]}
            updateResponse={updateResponse}
          />
        )}
        {mutableFrames[activeFrameIdx].type === 'conversation' && (
          <ConversationFrame
            content={mutableFrames[activeFrameIdx]}
            response={responses[activeFrameIdx]}
            updateResponse={updateResponse}
          />
        )}
        {mutableFrames[activeFrameIdx].type === 'visual' && (
          <VisualQuestionFrame
            content={mutableFrames[activeFrameIdx]}
            response={responses[activeFrameIdx]}
            updateResponse={updateResponse}
          />
        )}
      </ReadingFrame>
      <div className="row">
        {responses[activeFrameIdx].attempts >= 1 ? (
          <img src={check} alt="Attempted" />
        ) : (
          <img src={pencil} alt="In progress" />
        )}
        {activeFrameIdx > 0 && (
          <IconButton onClick={handlePrevious} icon="back" />
        )}
        <Button type="go" onClick={handleCheck} label="Submit" text="submit" />
        {activeFrameIdx < responses.length - 1 &&
          responses[activeFrameIdx].attempts > 0 && (
            <IconButton onClick={handleNext} icon="next" />
          )}
        <label className="label-small">Question {activeFrameIdx + 1}</label>
      </div>
    </div>
  );
};

export default MultipartAssignment;

interface FrameProps {
  content: MultiPartAssignmentContent;
  response: MultiPartAssignmentResponse;
  updateResponse: (
    index: number,
    response: MultiPartAssignmentResponse,
    resetCheck?: boolean,
  ) => void;
}

const QuestionFrame: React.FC<FrameProps> = ({
  content,
  response,
  updateResponse,
}) => {
  const [feedback, setFeedback] = useState<Record<string, string>>();
  const makeApiCall = useApiCall();

  useEffect(() => {
    if (!content.check) return;
    if (content.choices && content.choices.length) return;
    // check the student's short answer response
    makeApiCall(aiService.gradeShortAnswer, content.text, response.text)
      .then((resp) => {
        setFeedback(resp);
        updateResponse(
          content.index,
          {
            ...response,
            correct: resp.correct,
            feedback: resp.feedback,
          },
          false,
        );
      })
      .catch((error) => alert(error));
    // eslint-disable-next-line
  }, [content.check]);

  return (
    <div className="conversation-frame">
      <div className="question-input">
        <div className="row">
          <label className="label-medium">{content.text}</label>
          <AudioAssistanceButton text={content.text} />
        </div>
        {content.choices?.length ? (
          <div className="item-list">
            {content.choices.map((choice, idx) => (
              <ItemPanel
                key={`choice-${idx}`}
                id={`choice-${idx}`}
                selected={
                  content.check ? choice.correct : response.choice === idx
                }
                onClick={() =>
                  response.attempts === 0 &&
                  updateResponse(content.index, {
                    ...response,
                    correct: choice.correct,
                    choice: idx,
                  })
                }
                onDoubleClick={() =>
                  response.attempts === 0 &&
                  updateResponse(content.index, {
                    ...response,
                    correct: choice.correct,
                    choice: idx,
                  })
                }
                highlight={choice.correct && content.check}
              >
                <span className="label-medium">{choice.text}</span>
              </ItemPanel>
            ))}
          </div>
        ) : (
          <textarea
            value={response.text ?? ''}
            onChange={(event) =>
              updateResponse(content.index, {
                ...response,
                text: event.target.value,
              })
            }
          />
        )}
        {content.choices && response.choice && content.check ? (
          <img src={response.correct ? check : xMark} alt="" />
        ) : null}
        {feedback && (
          <div className="row">
            <img src={feedback.correct ? check : xMark} alt="" />
            <label className="label-small">{feedback.feedback}</label>
            <AudioAssistanceButton text={feedback.feedback} />
          </div>
        )}
      </div>
    </div>
  );
};

const ReadAloudFrame: React.FC<FrameProps> = ({
  content,
  response,
  updateResponse,
}) => {
  const [book, setBook] = useState<Book>();
  const [missedWords, setMissedWords] = useState<string[]>([]);
  const [isReading, setIsReading] = useState<boolean>(false);

  useEffect(() => {
    var book = Book.generateDefault();
    // book.words = content.text.split(' ');
    book.html_content = `<p>${content.text}</p>`;
    setBook(book);
  }, [content.text]);

  return (
    <>
      {book && (
        <BookReader
          book={book}
          startIndex={0}
          isReading={isReading}
          setIsReading={setIsReading}
          onMissedWord={(word: string) =>
            setMissedWords((prev) => [...prev, word])
          }
          showMissedWordPopup={false}
          onCompletion={() =>
            updateResponse(content.index, {
              ...response,
              attempts: response.attempts + 1,
              correct: true,
              feedback: `${missedWords.length} words missed: ${missedWords.join(
                ', ',
              )}`,
            })
          }
        />
      )}
    </>
  );
};

const ConversationFrame: React.FC<FrameProps> = ({
  content,
  response,
  updateResponse,
}) => {
  // eslint-disable-next-line
  const [isReading, setIsReading] = useState<boolean>(false);
  const [feedback, setFeedback] = useState<Record<string, string>>();
  const [audioSrc, setAudioSrc] = useState();
  const makeApiCall = useApiCall();

  useEffect(() => {
    // check the student's short answer response
    if (content.check && !content.choices) {
      makeApiCall(aiService.gradeShortAnswer, content.text, response.text)
        .then((resp) => {
          setFeedback(resp);
          updateResponse(
            content.index,
            {
              ...response,
              correct: resp.correct,
              feedback: resp.feedback,
            },
            false,
          );
        })
        .catch((error) => alert(error));
    }

    if (content.text && !audioSrc) {
      CachingEngine.getData(`${content.index}-conversation`).then(
        (cachedAudio) => {
          if (cachedAudio) {
            setAudioSrc(cachedAudio);
          } else {
            makeApiCall(googleApiService.convertTextToSpeech, content.text)
              .then((resp) => {
                setAudioSrc(resp);
                CachingEngine.setData(`${content.index}-conversation`, resp);
              })
              .catch((error) => alert(error));
          }
        },
      );
    }

    // eslint-disable-next-line
  }, [content.check, content.text, makeApiCall]);

  useEffect(() => {}, [content.text, makeApiCall]);

  const submitAudio = async (audioBlob: Blob) => {
    makeApiCall(googleApiService.convertSpeechToText, audioBlob)
      .then((resp) =>
        updateResponse(content.index, { ...response, text: resp }),
      )
      .catch((error) => alert(error));
  };

  return (
    <div className="conversation-frame">
      <div className="row">
        <label className="label-medium">{content.text}</label>
        <AudioAssistanceButton text={content.text} audioSrc={audioSrc} />
      </div>
      {response.text && <SpeechBubble text={response.text} />}
      <AudioRecorder
        onStartRecording={() => setIsReading(true)}
        onStopRecording={() => setIsReading(false)}
        onSubmit={submitAudio}
      />
      {feedback && (
        <div className="row">
          <img src={feedback.correct ? check : xMark} alt="" />
          <label className="label-small">{feedback.feedback}</label>
          <AudioAssistanceButton text={feedback.feedback} />
        </div>
      )}
    </div>
  );
};

const VisualQuestionFrame: React.FC<FrameProps> = ({
  content,
  response,
  updateResponse,
}) => {
  const [feedback, setFeedback] = useState<Record<string, string>>();
  const makeApiCall = useApiCall();

  useEffect(() => {
    if (!content.check) return;
    if (content.choices && content.choices.length) return;
    // check the student's short answer response
    makeApiCall(aiService.gradeShortAnswer, content.text, response.text)
      .then((resp) => {
        setFeedback(resp);
        updateResponse(
          content.index,
          {
            ...response,
            correct: resp.correct,
            feedback: resp.feedback,
          },
          false,
        );
      })
      .catch((error) => alert(error));
    // eslint-disable-next-line
  }, [content.check]);

  return (
    <div className="conversation-frame">
      <div className="question-input">
        <div className="row">
          <label className="label-medium">{content.text}</label>
          <AudioAssistanceButton text={content.text} />
        </div>
        {content.choices?.length ? (
          <Grid container>
            {content.choices.map((choice, idx) => (
              <Grid item>
                <ImageChoice
                  key={`choice-${idx}`}
                  id={`choice-${idx}`}
                  selected={
                    content.check ? choice.correct : response.choice === idx
                  }
                  onClick={() =>
                    response.attempts === 0 &&
                    updateResponse(content.index, {
                      ...response,
                      correct: choice.correct,
                      choice: idx,
                    })
                  }
                  onDoubleClick={() =>
                    response.attempts === 0 &&
                    updateResponse(content.index, {
                      ...response,
                      correct: choice.correct,
                      choice: idx,
                    })
                  }
                  highlight={choice.correct && content.check}
                >
                  <img src={choice.img} alt="" />
                </ImageChoice>
              </Grid>
            ))}
          </Grid>
        ) : (
          <textarea
            value={response.text ?? ''}
            onChange={(event) =>
              updateResponse(content.index, {
                ...response,
                text: event.target.value,
              })
            }
          />
        )}
        {content.choices && response.choice && content.check ? (
          <img src={response.correct ? check : xMark} alt="" />
        ) : null}
        {feedback && (
          <div className="row">
            <img src={feedback.correct ? check : xMark} alt="" />
            <label className="label-small">{feedback.feedback}</label>
            <AudioAssistanceButton text={feedback.feedback} />
          </div>
        )}
      </div>
    </div>
  );
};
