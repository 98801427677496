import React from 'react';
import { MultiPartAssignmentContent } from 'view/components/student/newcomers/MultipartAssignment';

import 'view/style/teacher/components/assignment/newcomer.css';

interface MultiPartConversationFrameProps {
  content: MultiPartAssignmentContent;
  onUpdateContent: (updatedContent: MultiPartAssignmentContent) => void;
}

const MultiPartConversationFrame: React.FC<MultiPartConversationFrameProps> = ({
  content,
  onUpdateContent,
}) => {
  const handleUpdatePrompt = (text: string) => {
    const updatedContent = {
      ...content,
      text: text,
    };

    onUpdateContent(updatedContent);
  };

  return (
    <div className="multi-part-assignment-component">
      <textarea
        id={`${content.index}-prompt`}
        placeholder="Type your prompt here"
        value={content.text}
        onChange={(e) => handleUpdatePrompt(e.target.value)}
        style={{ width: '100%', height: '100%' }}
      />
    </div>
  );
};

export default MultiPartConversationFrame;
