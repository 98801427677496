import { useUserPreferences } from 'contexts/UserPreferencesContext';
import React, { ReactNode, useState } from 'react';
import dictionaryService from 'services/dictionaryService';
import Modal from './Modal';
import { AudioAssistanceButton } from '../buttons/AudioAssistanceButton';

type ReadingFrameProps = {
  isBlurred?: boolean;
  isDyslexic?: boolean;
  isInteractive?: boolean;
  interactionCallback?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => void;
  children: ReactNode;
};

interface MenuPosition {
  top: number;
  left: number;
}

const ReadingFrame: React.FC<ReadingFrameProps> = ({
  isBlurred = false,
  isDyslexic = false,
  isInteractive = false,
  interactionCallback,
  children,
}) => {
  const { fontScale, lineSpacing } = useUserPreferences();

  document.addEventListener('copy', function (e) {
    e.preventDefault();
  });

  const [selectedText, setSelectedText] = useState<string>('');
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [menuPosition, setMenuPosition] = useState<MenuPosition>({
    top: 0,
    left: 0,
  });
  const [phraseDefinition, setPhraseDefinition] = useState<
    string | undefined
  >();
  const [phrase, setPhrase] = useState<string>('');

  const handleMouseUp = () => {
    const selected = window.getSelection()?.toString().trim() || '';
    if (selected) {
      setSelectedText(selected);
    } else {
      setSelectedText('');
      setShowMenu(false); // Hide the menu if no text is selected
    }
  };

  const handleContextMenu = (event: React.MouseEvent) => {
    if (selectedText) {
      event.preventDefault();
      console.log('Right-click detected and text selected:', selectedText);
      setMenuPosition({ top: event.pageY, left: event.pageX });
      setShowMenu(true);
    }
  };

  const handleGetDefinition = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();

    if (selectedText) {
      console.log(`Fetching definition for: ${selectedText}`);
      dictionaryService
        .getPhraseDefinition(selectedText)
        .then((resp) => {
          setPhraseDefinition(resp?.definition);
          setPhrase(selectedText);
        })
        .catch((error) => console.error(error))
        .finally(() => {
          setShowMenu(false);
          setSelectedText('');
        });
    }
  };

  // Prevent right-click and other events from propagating when interacting with the menu
  const handleMenuClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    console.log('Menu clicked, stopping propagation');
  };

  return (
    <>
      <div
        className={`reading-frame ${isBlurred ? 'blurred' : ''} ${
          isDyslexic ? 'dyslexic' : ''
        }`}
        style={
          {
            '--font-scale': fontScale,
            '--line-spacing-scale': lineSpacing,
          } as React.CSSProperties
        }
        onClick={(event) => {
          if (isInteractive && interactionCallback) {
            event.preventDefault();
            interactionCallback(event);
          }
        }} // Removed stopPropagation here
        onMouseUp={handleMouseUp}
        onContextMenu={handleContextMenu}
      >
        {children}
      </div>
      {showMenu && (
        <ul
          style={{
            position: 'absolute',
            top: `${menuPosition.top}px`,
            left: `${menuPosition.left}px`,
            backgroundColor: '#f9f9f9', // Chrome-like context menu background color
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)', // Subtle shadow for depth
            padding: '4px 0', // Space between menu items
            margin: 0,
            borderRadius: '4px', // Rounded corners like Chrome
            width: '200px',
            fontSize: '14px',
            zIndex: 1000,
          }}
          onClick={handleMenuClick}
          onContextMenu={handleMenuClick}
        >
          <li
            onClick={handleGetDefinition}
            style={{
              padding: '8px 16px',
              cursor: 'pointer',
              listStyleType: 'none',
              backgroundColor: 'transparent',
              color: '#333',
            }}
            onMouseEnter={(e) =>
              (e.currentTarget.style.backgroundColor = '#e5e5e5')
            }
            onMouseLeave={(e) =>
              (e.currentTarget.style.backgroundColor = 'transparent')
            }
          >
            Get Definition
          </li>
        </ul>
      )}
      <Modal
        isOpen={phraseDefinition !== undefined}
        onClose={() => setPhraseDefinition(undefined)}
        style={{ width: '800px', maxWidth: '80vw' }}
      >
        <PhraseDefinitionPopup phrase={phrase} definition={phraseDefinition} />
      </Modal>
    </>
  );
};

export default ReadingFrame;

type PhraseDefinitionPopupProps = {
  phrase: string;
  definition?: string;
  errorMessage?: string;
};

const PhraseDefinitionPopup: React.FC<PhraseDefinitionPopupProps> = ({
  phrase,
  definition,
  errorMessage,
}) => {
  return (
    <>
      <label className="label-large">Phrase definition</label>
      <div className="missed-word-popup-content">
        <label className="label-xl">"{phrase}"</label>
        <div className="missed-word-definition">
          <span className="book-text">
            <div className="row">
              <h5>{definition}</h5>
              <AudioAssistanceButton
                text={definition ?? 'No definition found'}
                lang={dictionaryService.language.code}
              />
            </div>
          </span>
        </div>
      </div>
    </>
  );
};
