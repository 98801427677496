import React, { useState } from 'react';
import Assignment from 'models/Assignment';
import ActionButton from 'view/components/buttons/ActionButton';
import Book from 'models/Book';
import { useParams } from 'react-router-dom';
import AssignmentSidebar from '../AssignmentSidebar';
import LanguageSelector from 'view/components/common/LanguageSelector';
import { UserInput } from 'view/components/common/UserInput';
import useApiCall from 'contexts/ApiCall';
import aiService from 'services/aiService';
import {
  MultiPartAssignmentContent,
  MultiPartAssignmentContentType,
} from 'view/components/student/newcomers/MultipartAssignment';
import MultiPartQuestionFrame from './MultipartQuestionFrame';
import MultiPartConversationFrame from './MultipartConversationFrame';
import MultiPartReadAloudFrame from './MultipartReadAloudFrame';
import IconButton from 'view/components/buttons/IconButton';

import 'view/style/student/components/newcomer/multipartAssignment.css';

const delete_forever: string =
  require('assets/icons/delete_forever.svg').default;
const file_upload: string = require('assets/icons/file_upload.svg').default;
const menu_book: string = require('assets/icons/menu_book.svg').default;

interface MultiPartAssignmentFormProps {
  assignment: Assignment;
  book: Book;
  setAssignment: React.Dispatch<React.SetStateAction<Assignment | undefined>>;
  updateAssignment: (event: any) => void;
  updateBook: (event: any) => void;
  updateContent: (content?: Record<any, any>) => void;
  handleInputFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleBookCoverChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleCreateAssignment: (shouldAnalyze: boolean) => void;
  handleDeleteAssignment: () => void;
  handleSelectCatalogBook: (book: Book) => void;
  onCancel: () => void;
}

const MultiPartAssignmentForm: React.FC<MultiPartAssignmentFormProps> = ({
  assignment,
  book,
  setAssignment,
  updateAssignment,
  updateBook,
  updateContent,
  handleInputFileChange,
  handleBookCoverChange,
  handleCreateAssignment,
  handleDeleteAssignment,
  onCancel,
}) => {
  const { assignmentId } = useParams();
  // eslint-disable-next-line
  const [frames, setFrames] = useState<
    Record<number, MultiPartAssignmentContent> | undefined
  >(book.json_content ?? undefined);
  const [topic, setTopic] = useState<string>('Any');
  const [readingDifficulty, setReadingDifficulty] = useState<string>('');
  const [length, setLength] = useState<number>(6);
  const makeApiCall = useApiCall();

  const handleGenerateAssignment = () => {
    makeApiCall(
      aiService.generateNewcomerJson,
      topic,
      readingDifficulty,
      length,
    )
      .then((resp) => handleUpdateFrames(resp))
      .catch((error) => alert(error));
  };

  const handleStartFromScratch = () => {
    setFrames({ 0: { ...initialContent } });
  };

  const handleUpdateFrames = (
    updatedFrames?: Record<number, MultiPartAssignmentContent>,
  ) => {
    updateContent(updatedFrames);
    setFrames(updatedFrames);
  };

  return (
    <div className="app-page-content">
      <AssignmentSidebar
        assignment={assignment}
        book={book}
        options={[]}
        handleBookCoverChange={handleBookCoverChange}
        handleInputFileChange={handleInputFileChange}
        onCancel={onCancel}
        setAssignment={setAssignment}
        updateAssignment={updateAssignment}
        updateBook={updateContent}
      >
        <>
          {assignmentId && (
            <ActionButton
              type="delete"
              onClick={handleDeleteAssignment}
              label="Delete"
              icon={delete_forever}
            />
          )}
          <ActionButton
            type="go"
            onClick={() => handleCreateAssignment(false)}
            label={assignmentId ? 'Update' : 'Upload'}
            icon={file_upload}
          />
        </>
      </AssignmentSidebar>
      <div className="app-main-content">
        <div className="book-details-editor">
          <div className="assignment-editor-title">
            <img src={menu_book} alt="" />
            <input
              className="title"
              type="text"
              placeholder="Assignment Name"
              id="title"
              value={book?.title}
              onChange={(event) => {
                updateBook(event);
                updateAssignment(event);
              }}
            />
          </div>
        </div>
        {frames ? (
          <MultiPartAssignment frames={frames} setFrames={handleUpdateFrames} />
        ) : (
          <div className="reading-frame">
            <UserInput
              type="text"
              id="topic"
              label="Topic"
              value={topic}
              onChange={(event) => setTopic(event.target.value)}
            />
            <UserInput
              type="text"
              id="topic"
              label="Reading difficulty"
              value={readingDifficulty}
              onChange={(event) => setReadingDifficulty(event.target.value)}
            />
            <UserInput
              type="number"
              id="topic"
              label="Length"
              value={length}
              onChange={(event) => setLength(event.target.value)}
            />
            <LanguageSelector />
            <div className="row">
              <ActionButton
                onClick={handleGenerateAssignment}
                type="go"
                label="Generate"
              />
              OR
              <ActionButton
                onClick={handleStartFromScratch}
                type="edit"
                label="Start from scratch"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MultiPartAssignmentForm;

type MultiPartAssignmentProps = {
  frames: Record<number, MultiPartAssignmentContent>;
  setFrames: (
    updatedFrames?: Record<number, MultiPartAssignmentContent>,
  ) => void;
};

const initialContent: MultiPartAssignmentContent = {
  type: 'multipleChoice',
  text: '',
  choices: [],
  index: 0,
  id: 0,
};

const MultiPartAssignment: React.FC<MultiPartAssignmentProps> = ({
  frames,
  setFrames,
}) => {
  const [currentPage, setCurrentPage] = useState(0);

  const handleNext = () => {
    if (currentPage < Object.keys(frames).length - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleContentUpdate = (updatedContent: MultiPartAssignmentContent) => {
    const updatedFrames = {
      ...frames,
      [updatedContent.index]: { ...updatedContent },
    };

    setFrames(updatedFrames);
  };

  const handleDeleteFrame = (index: number) => {
    setCurrentPage((prev) => Math.max(prev - 1, 0));
    if (Object.keys(frames).length <= 1) {
      setFrames();
    } else {
      const updatedFrames: Record<number, MultiPartAssignmentContent> = {};

      // Step 1: Remove the specific key from the dictionary
      Object.keys(frames!).forEach((keyString) => {
        const key = parseInt(keyString, 10);
        if (key < index) {
          // Step 2: Copy all keys before the deleted key without changes
          updatedFrames[key] = frames![key];
        } else if (key > index) {
          // Step 3: Shift keys after the deleted key by subtracting 1
          updatedFrames[key - 1] = frames![key];
        }
      });

      setFrames(updatedFrames);
    }
  };

  const handleAddPage = () => {
    const num_pages = Object.keys(frames).length;

    const updatedFrames = {
      ...frames,
      [num_pages]: { ...initialContent, index: num_pages },
    };

    setFrames(updatedFrames);
    setCurrentPage(num_pages);
  };

  const handleChangeContentType = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const updatedFrames = {
      ...frames,
      [currentPage]: {
        ...frames![currentPage],
        type: event.target.value as MultiPartAssignmentContentType,
      },
    };

    setFrames(updatedFrames);
  };

  return (
    <>
      <div className="reading-frame">
        {(frames[currentPage].type === 'multipleChoice' ||
          frames[currentPage].type === 'shortAnswer' ||
          frames[currentPage].type === 'visual') && (
          <MultiPartQuestionFrame
            content={frames[currentPage]}
            onUpdateContent={handleContentUpdate}
            onDeleteFrame={handleDeleteFrame}
          />
        )}
        {frames[currentPage].type === 'conversation' && (
          <MultiPartConversationFrame
            content={frames[currentPage]}
            onUpdateContent={handleContentUpdate}
          />
        )}
        {frames[currentPage].type === 'readAloud' && (
          <MultiPartReadAloudFrame
            content={frames[currentPage]}
            onUpdateContent={handleContentUpdate}
          />
        )}
      </div>
      <div className="row">
        {currentPage > 0 && (
          <IconButton
            onClick={handlePrevious}
            icon="back"
            disabled={currentPage === 0}
          />
        )}
        <select
          value={frames[currentPage].type}
          onChange={handleChangeContentType}
        >
          <option value="multipleChoice">Multiple Choice</option>
          <option value="shortAnswer">Short Answer</option>
          <option value="conversation">Conversation</option>
          <option value="readAloud">Read Aloud</option>
          <option value="visual">Visual</option>
        </select>
        {currentPage < Object.keys(frames).length - 1 ? (
          <IconButton onClick={handleNext} icon="next" />
        ) : (
          <IconButton onClick={handleAddPage} icon="plus" />
        )}
      </div>
    </>
  );
};
