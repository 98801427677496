import MultipleChoiceOption from './MultipleChoiceOption';

export default class Question {
  private readonly id;
  public assignment: string;
  public text: string;
  public index: number;
  public max_points: number;
  public choices?: MultipleChoiceOption[];

  constructor(
    id: string,
    assignment: string,
    text: string,
    index: number,
    max_points: number,
    choices?: MultipleChoiceOption[],
  ) {
    this.id = id;
    this.assignment = assignment;
    this.text = text;
    this.index = index;
    this.max_points = max_points;
    this.choices = choices;
  }

  static fromQuestion(question: Question) {
    return new Question(
      question.getId(),
      question.assignment,
      question.text,
      question.index,
      question.max_points,
      question.choices,
    );
  }

  static fromServerQuestion(question: Record<string, any>): Question {
    return new Question(
      question['id'],
      question['assignment'],
      question['text'],
      question['index'],
      question['max_points'],
      question['choices'],
    );
  }

  public getId(): string {
    return this.id;
  }

  public toJSON(): Record<string, any> {
    return {
      id: this.id,
      assignment: this.assignment,
      text: this.text,
      index: this.index,
      max_points: this.max_points,
      choices: this.choices?.map((c) => c.toJSON()),
    };
  }
}
