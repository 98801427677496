import React, { useEffect, useState } from 'react';
import { UserInput } from 'view/components/common/UserInput';
import ActionButton from 'view/components/buttons/ActionButton';
import MultipleChoiceOption from 'models/MultipleChoiceOption';
import { adminService } from 'services/adminService';
import Book from 'models/Book';
import useApiCall from 'contexts/ApiCall';
import IconButton from 'view/components/buttons/IconButton';

import 'view/style/teacher/components/assignment/questionEditor.css';

const trash: string = require('assets/icons/delete_forever_red.svg').default;

export type DefaultQuestion = {
  id: string;
  text: string;
  answer?: string;
  book: string;
  choices?: MultipleChoiceOption[];
};

interface QuestionEditorProps {
  book: Book;
  onExit: () => void;
}

const AdminQuestionEditor: React.FC<QuestionEditorProps> = ({
  book,
  onExit,
}) => {
  const [newQuestionText, setNewQuestionText] = useState<string>('');
  const [editorQuestions, setEditorQuestions] = useState<DefaultQuestion[]>([]);
  const makeApiCall = useApiCall();

  useEffect(() => {
    if (book.default_questions?.length) {
      setEditorQuestions(
        book.default_questions.sort((q1, q2) => Number(q1.id) - Number(q2.id)),
      );
      return;
    }
    // if there are no default questions yet, auto generate them
    // makeApiCall(adminService.generateDefaultQuestions, book.getText())
    //   .then((resp) => {
    //     if (!resp.questions) {
    //       console.log(resp);
    //       setNewQuestionText(resp.toString());
    //     } else {
    //       setEditorQuestions(
    //         [
    //           ...resp.questions.map(
    //             (q: Record<string, string>, idx: number) => {
    //               const question = { ...q, book: book.id, id: idx };
    //               makeApiCall(
    //                 adminService.createDefaultQuestion,
    //                 question,
    //               ).catch((err) => alert(err));
    //               return question;
    //             },
    //           ),
    //         ].sort((q1, q2) => Number(q1.id) - Number(q2.id)),
    //       );
    //     }
    //   })
    //   .catch((err) => alert(err));
  }, [book]);

  const handleQuestionTextChange = (
    question: DefaultQuestion,
    text: string,
  ) => {
    const newQuestion = {
      ...question,
      text: text,
    };
    setEditorQuestions((prevQuestions) =>
      [...prevQuestions.filter((q) => q.id !== question.id), newQuestion].sort(
        (q1, q2) => Number(q1.id) - Number(q2.id),
      ),
    );
  };

  const handleCreateQuestion = () => {
    if (newQuestionText === '') return;
    const question = {
      text: newQuestionText,
      book: book.id,
    };
    // onCreateQuestion(newQuestionText);
    makeApiCall(adminService.createDefaultQuestion, question)
      .then((resp) => {
        alert('Question created');
        setEditorQuestions((prevQuestions) =>
          [...prevQuestions, resp].sort(
            (q1, q2) => Number(q1.id) - Number(q2.id),
          ),
        );
      })
      .catch((err) => alert(err));
    setNewQuestionText('');
  };

  const handleUpdateQuestion = (question: DefaultQuestion) => {
    makeApiCall(adminService.updateDefaultQuestion, question)
      .then((resp) => alert('Question updated'))
      .catch((err) => alert(err));
  };

  const handleDeleteQuestion = (question: DefaultQuestion) => {
    makeApiCall(adminService.deleteDefaultQuestion, question)
      .then((resp) => {
        alert('Question deleted');
        setEditorQuestions((prevQuestions) =>
          [...prevQuestions.filter((q) => q.id !== question.id)].sort(
            (q1, q2) => Number(q1.id) - Number(q2.id),
          ),
        );
      })
      .catch((err) => alert(err));
  };

  const handleAddQuestionChoice = (question: DefaultQuestion) => {
    const newQuestion = {
      ...question,
      choices: [
        ...(question.choices ?? []),
        new MultipleChoiceOption(
          question.choices?.length.toString() ?? '0',
          question.id,
          '',
          false,
        ),
      ].sort((q1, q2) => Number(q1.id) - Number(q2.id)),
    };
    setEditorQuestions((prevQuestions) =>
      [...prevQuestions.filter((q) => q.id !== question.id), newQuestion].sort(
        (q1, q2) => Number(q1.id) - Number(q2.id),
      ),
    );
  };

  const handleModifyQuestionChoice = (
    question: DefaultQuestion,
    choice: MultipleChoiceOption,
    text: string,
    correct: boolean,
  ) => {
    const newQuestion = {
      ...question,
      choices: [
        ...(question.choices ?? []).filter((c) => c !== choice),
        new MultipleChoiceOption(
          choice.id,
          question.id,
          text,
          correct,
          choice.img,
          choice.img_file,
        ),
      ].sort((q1, q2) => Number(q1.id) - Number(q2.id)),
    };
    setEditorQuestions((prevQuestions) =>
      [...prevQuestions.filter((q) => q.id !== question.id), newQuestion].sort(
        (q1, q2) => Number(q1.id) - Number(q2.id),
      ),
    );
  };

  const handleDeleteQuestionChoice = (
    question: DefaultQuestion,
    choice: MultipleChoiceOption,
  ) => {
    const newQuestion = {
      ...question,
      choices: [...question.choices!.filter((c) => c !== choice)],
    };
    setEditorQuestions((prevQuestions) => [
      ...prevQuestions.filter((q) => q.id !== question.id),
      newQuestion,
    ]);
  };

  const handleSave = () => {
    // just resave every question
    editorQuestions.forEach((q) => handleUpdateQuestion(q));
    onExit();
  };

  return (
    <div className="question-editor">
      <label className="label-large">Edit Questions</label>
      {editorQuestions.map((question, idx) => (
        <>
          <UserInput
            key={`question-${question.id}`}
            id={`question-${question.id}`}
            label={`Question ${question.id}`}
            type="text"
            value={question.text}
            onChange={(event) =>
              handleQuestionTextChange(question, event.target.value)
            }
          />
          {question.choices &&
            question.choices.map((choice, choiceIdx) => (
              <div
                key={`choice-${choiceIdx}`}
                className="multiple-choice-option"
              >
                <input
                  type="checkbox"
                  checked={choice.correct}
                  onChange={(event) =>
                    handleModifyQuestionChoice(
                      question,
                      choice,
                      choice.text,
                      event.target.checked,
                    )
                  }
                />
                <input
                  type="text"
                  value={choice.text}
                  onChange={(event) =>
                    handleModifyQuestionChoice(
                      question,
                      choice,
                      event.target.value,
                      choice.correct,
                    )
                  }
                />
                <IconButton
                  icon={trash}
                  type="transparent"
                  onClick={() => handleDeleteQuestionChoice(question, choice)}
                />
              </div>
            ))}
          <div
            key={`question-${question.id}-buttons`}
            className="action-buttons"
          >
            <ActionButton
              type="go"
              onClick={() => handleAddQuestionChoice(question)}
              label="Add choice"
            />
            <ActionButton
              type="edit"
              onClick={() => handleUpdateQuestion(question)}
              label="Update"
            />
            <ActionButton
              type="delete"
              onClick={() => handleDeleteQuestion(question)}
              label="Delete"
            />
          </div>
        </>
      ))}
      <UserInput
        id="add-question"
        label="Add Question"
        type="text"
        value={newQuestionText}
        onChange={(event) => setNewQuestionText(event.target.value)}
      />
      <div className="action-buttons">
        <ActionButton
          type="edit"
          onClick={handleCreateQuestion}
          label="Add Question"
        />
        <ActionButton type="go" onClick={handleSave} label="Save changes" />
      </div>
    </div>
  );
};

export default AdminQuestionEditor;
